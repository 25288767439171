let backendHost;

const hostname = window && window.location && window.location.hostname;

if (hostname.includes('staging.etaxe-ci.com') || hostname.includes('localhost')) {
  backendHost = 'https://api-staging.etaxe-ci.com/api/';
} else {
  backendHost = 'https://api-prod.etaxe-ci.com/api/';
}

export const API_ROOT = `${backendHost}`;
