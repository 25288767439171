// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  banking: getIcon('ic_banking'),
  maps: getIcon('ic_kanban'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  blog: getIcon('ic_blog'),
  book: getIcon('ic_booking')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'general',
    items: [
      { title: 'Tableau De Bord', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
      { title: 'Contribuables', path: PATH_DASHBOARD.general.taxpayers, icon: ICONS.ecommerce },
      { title: 'Collecteurs', path: PATH_DASHBOARD.general.collectors, icon: ICONS.analytics },
      { title: 'Administrateurs', path: PATH_DASHBOARD.general.administrators, icon: ICONS.banking },
      { title: 'tickets', path: PATH_DASHBOARD.general.tickets, icon: ICONS.mail },
      { title: 'payments', path: PATH_DASHBOARD.general.payments, icon: ICONS.blog },
      { title: 'stock', path: PATH_DASHBOARD.general.stockTicket, icon: ICONS.book },
      { title: 'Cartographie', path: PATH_DASHBOARD.general.cartographie, icon: ICONS.maps },
      { title: 'Activités', path: PATH_DASHBOARD.general.activity, icon: ICONS.cart },
      { title: 'Zone', path: PATH_DASHBOARD.general.zone, icon: ICONS.chat }
    ]
  }

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.app.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: PATH_DASHBOARD.app.pageFour },
  //         { title: 'Five', path: PATH_DASHBOARD.app.pageFive },
  //         { title: 'Six', path: PATH_DASHBOARD.app.pageSix }
  //       ]
  //     }
  //   ]
  // }
];

export default sidebarConfig;
