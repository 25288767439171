// import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  allTaxPayers: []
};

const slice = createSlice({
  name: 'taxPayer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TAXPAYERS
    getTaxPayers(state, action) {
      state.isLoading = false;
      state.allTaxPayers = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function getAllTaxPayers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('public/contribuables');
      dispatch(slice.actions.getTaxPayers(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
