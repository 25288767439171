// import { Icon } from '@iconify/react';
// import homeFill from '@iconify/icons-eva/home-fill';
// import fileFill from '@iconify/icons-eva/file-fill';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

// const ICON_SIZE = {
//   width: 22,
//   height: 22
// };

const menuConfig = [
  // {
  //   title: 'Acceuil',
  //   path: '/',
  //   icon: <Icon icon={homeFill} {...ICON_SIZE} />
  // },
  // { title: 'Contact', path: '/contact-us', icon: <Icon icon={fileFill} {...ICON_SIZE} /> },
  // { title: 'A Propos', path: PATH_DASHBOARD.root, icon: <Icon icon={fileFill} {...ICON_SIZE} /> }
];

export default menuConfig;
