import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'reset-password', element: <ResetPassword /> }
      ]
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/dashboard/index" replace /> },
        { path: 'index', element: <Dashboard /> },
        { path: 'taxpayers', element: <Taxpayers /> },
        { path: 'taxpayers/paiement/:id', element: <Taxpayerspayment /> },
        { path: 'collectors', element: <Collectors /> },
        { path: 'administrators', element: <Administrators /> },
        { path: 'cartography', element: <Cartography /> },
        { path: 'collectors/new', element: <AddCollector /> },
        { path: 'collectors/:id/edit', element: <AddCollector /> },
        { path: 'administrators/new', element: <AddAdministrator /> },
        { path: 'administrators/:id/edit', element: <AddAdministrator /> },
        { path: 'taxpayers/details/:id', element: <TaxpayerDetails /> },
        { path: 'activity', element: <Activity /> },
        { path: 'activity/new', element: <AddActivity /> },
        { path: 'activity/:id/edit', element: <AddActivity /> },
        { path: 'zone', element: <Zone /> },
        { path: 'zone/new', element: <AddZone /> },
        { path: 'zone/:id/edit', element: <AddZone /> },
        { path: 'tickets', element: <Tickets /> },
        { path: 'tickets/retour/:id', element: <ReturnTickets /> },
        { path: 'tickets/distribution', element: <RetraitTickets /> },
        { path: 'payments', element: <Payments /> },
        { path: 'collectors/paiement/:id', element: <CollectorPayments /> },
        { path: 'collectors/histotique-ticket/:id', element: <BilletsHistoriques /> },
        { path: 'taxpayers/ranking', element: <TaxpayerRanking /> },
        { path: 'collectors/ranking', element: <CollectorsRanking /> },
        { path: 'stock', element: <StockTicket /> },
        { path: 'ajout-stock-initial', element: <AjoutStockInitial /> },
        { path: 'ajout-stock-initial/:id/edit', element: <AjoutStockInitial /> }
      ]
    },

    // Main Routes
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: 'contact-us', element: <Contact /> }
      ]
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    // {
    //   path: '/',
    //   children: [
    //     {
    //       path: '/',
    //       element: (
    //         <GuestGuard>
    //           <Login />
    //         </GuestGuard>
    //       )
    //     }
    //   ]
    // },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));

// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Taxpayers = Loadable(lazy(() => import('../pages/Taxpayers')));
const TaxpayerDetails = Loadable(lazy(() => import('../pages/TaxpayersDetails')));
const Collectors = Loadable(lazy(() => import('../pages/Collectors')));
const Administrators = Loadable(lazy(() => import('../pages/Administrator')));
const AddCollector = Loadable(lazy(() => import('../pages/AddCollector')));
const AddAdministrator = Loadable(lazy(() => import('../pages/AddAdministrator')));
const Cartography = Loadable(lazy(() => import('../pages/Cartography')));
const Taxpayerspayment = Loadable(lazy(() => import('../pages/TaxpayersPayment')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Activity = Loadable(lazy(() => import('../pages/Activity')));
const AddActivity = Loadable(lazy(() => import('../pages/AddActivity')));
const Zone = Loadable(lazy(() => import('../pages/Zone')));
const AddZone = Loadable(lazy(() => import('../pages/AddZone')));
const Tickets = Loadable(lazy(() => import('../pages/Tickets')));
const ReturnTickets = Loadable(lazy(() => import('../pages/ReturnTickets')));
const RetraitTickets = Loadable(lazy(() => import('../pages/RetraitTickets')));
const Payments = Loadable(lazy(() => import('../pages/Payments')));
const CollectorPayments = Loadable(lazy(() => import('../pages/CollectorPayments')));
const BilletsHistoriques = Loadable(lazy(() => import('../pages/BilletsHistoriquesCollector')));
const TaxpayerRanking = Loadable(lazy(() => import('../pages/TaxpayerRanking')));
const CollectorsRanking = Loadable(lazy(() => import('../pages/CollectorsRanking')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const StockTicket = Loadable(lazy(() => import('../pages/StockTicket')));
const AjoutStockInitial = Loadable(lazy(() => import('../pages/AjoutStockInitial')));
