// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_AUTH = '/auth';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  resetPassword: path(ROOTS_AUTH, '/reset-password')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/index'),
    taxpayers: path(ROOTS_DASHBOARD, '/taxpayers'),
    taxpayerspayment: path(ROOTS_DASHBOARD, '/taxpayers/paiement/:id'),
    collectors: path(ROOTS_DASHBOARD, '/collectors'),
    administrators: path(ROOTS_DASHBOARD, '/administrators'),
    addCollector: path(ROOTS_DASHBOARD, '/collectors/new'),
    addAdministrators: path(ROOTS_DASHBOARD, '/administrators/new'),
    cartographie: path(ROOTS_DASHBOARD, '/cartography'),
    taxpayerDetails: path(ROOTS_DASHBOARD, '/taxpayers/details/:id'),
    activity: path(ROOTS_DASHBOARD, '/activity'),
    addActivity: path(ROOTS_DASHBOARD, '/activity/new'),
    zone: path(ROOTS_DASHBOARD, '/zone'),
    addZone: path(ROOTS_DASHBOARD, '/zone/new'),
    tickets: path(ROOTS_DASHBOARD, '/tickets'),
    returnTickets: path(ROOTS_DASHBOARD, '/tickets/retour'),
    retraitTickets: path(ROOTS_DASHBOARD, '/tickets/distribution'),
    payments: path(ROOTS_DASHBOARD, '/payments'),
    collectorPayments: path(ROOTS_DASHBOARD, '/collectors/paiement/:id'),
    billetsHistoriques: path(ROOTS_DASHBOARD, '/collectors/histotique-ticket/:id'),
    taxpayersRanking: path(ROOTS_DASHBOARD, '/taxpayers/ranking'),
    collectorsRanking: path(ROOTS_DASHBOARD, '/collectors/ranking'),
    contact: path(ROOTS_DASHBOARD, '/contact-us'),
    editCollector: path(ROOTS_DASHBOARD, '/collectors/:id/edit'),
    editZone: path(ROOTS_DASHBOARD, '/zone/:id/edit'),
    stockTicket: path(ROOTS_DASHBOARD, '/stock'),
    stockInitial: path(ROOTS_DASHBOARD, '/ajout-stock-initial'),
    editStockInitial: path(ROOTS_DASHBOARD, '/ajout-stock-initial/:id/edit')
  }
};
